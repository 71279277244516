<template>
  <div class="about">
    <!-- <p style="font-size: 10px;font-weight: lighter;">加载中...</p> -->
  </div>
</template>
<script>
import { getFileUrl } from "@/utils/files.data";
export default {
  name: 'Material',
	data () {
		return {

		}
	},
	created () {
		const { collect, filename } = this.$route.params
		const fileUrl = getFileUrl(collect, filename)
		console.log('fileUrl:', fileUrl);
		fileUrl && (window.location.href = fileUrl)
	}
}
</script>
